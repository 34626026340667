import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Environment } from '../../models/Environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  private environment: Environment;
  userTypes$: any[];
  userTrades$: any[];
  userTrades2$: any[];
  userDivisions$: any[];
  userDivisions2$: any[];
  selectedTrades = []; // User selected Trades
  selectedDivisions = []; // User selected Divisions
  selectedDivisionLineItems = []; // Selected Division Line Items
  userSelectedDivisions = [];
  previewUrl: any;
  junkObj: {};
  showView1 = true;
  showLoder = false;
  maxColumns: number;
  maxRows: number;
  m: number;
  colNum: number;
  finalColNum: number;
  id: any;
  tempArray: any[];
  tradesmanId: number;
  vendorId: number;
  showTrades: boolean = false;
  selectedUserType: number = null;
  filterargs = [{ Name: 'Owner' },
  { Name: 'Appraiser' },
  { Name: 'Bank' },
  { Name: 'Gamer' },
  { Name: 'Architect' },
  { Name: 'PrivateLabelUser' }];

  col1 = [{ Column: 0 }];
  col2 = [{ Column: 1 }];
  col3 = [{ Column: 2 }];

  dummyModel: any = {
    firstName: '',
    lastName: '',
    emailConfirm: '',
    passwordConfirm: ''
  };

  model: any = {
    Id: 0,
    Name: '',
    User: {
      Id: 0,
      IsPrivateLabelPartner: true,
      IsPrivateLabelUser: false,
      Name: '',
      UserName: '',
      Email: '',
      Password: ''
    },
    LogoUrl: '',
    WebsiteUrl: '',
    UserTypeId: 0,
    Divisions: [],
    Trades: []
  };

  errorUploadingLogo: boolean = false;

  emailValidationLink = '';
  showPasswordText: boolean = false;
  showConfirmPasswordText: boolean = false;

  isUserLoggedIn = false;

  // ctor()
  // inject DataService
  constructor(private data: DataService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.environment = new Environment();
  }

  async ngOnInit() {
  }

  async ngAfterViewInit() {
    this.isUserLoggedIn = await this.authenticate();

    this.data.getUserTypes().subscribe((data: any[]) => {
      data = data.filter(x => x.Name !== 'Unassigned');

      this.userTypes$ = data;

      const tradesman = data.find((user) => user.Name.includes("tradesman"));
      const vendor = data.find((user) => user.Name.includes("vendor"));

      if (tradesman) {
        this.tradesmanId = tradesman.Id;
        this.selectedUserType = this.tradesmanId;
      }

      if (vendor) {
        this.vendorId = vendor.Id;
      }

    });

    this.data.getTrades().subscribe(
      data => this.getTrades(data as any[])
    );

    this.data.getDivisions().subscribe(
      // data => this.userDivisions$ = data as []
      data => this.getDivisions(data as any[])
    );

  }

  redirectToAzure() {
    window.location.href = this.environment.determineAzureB2CUrl();
  }

  async authenticate(): Promise<boolean> {

    let a = await this.isLoggedIn();

    if (!a) {
      const url = this.activatedRoute.snapshot.fragment;  // get the fragment part of the URL
      if (!url) return false;

      // parse the fragment, looking for "access_token"
      const params = new URLSearchParams(url);
      const token = params.get('access_token');
      const tokenType = params.get('token_type');
      const expiresIn = params.get('expires_in');

      if (!token || tokenType !== 'Bearer' || !expiresIn) {
        return false;
      }

      // Optionally: store the token, tokenType, expiresIn somewhere for future use
      localStorage.setItem('plbor_AuthToken', token);
      localStorage.setItem('plbor_token_type', tokenType);
      localStorage.setItem('plbor_expires_in', expiresIn);

      // get the current timestamp in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // calculate the expiration timestamp of the token
      const expirationTime = currentTime + parseInt(expiresIn);

      localStorage.setItem('plbor_expiration_time', expirationTime.toString());
    }

    return true;
  }

  async isLoggedIn(): Promise<boolean> {
    const expirationTime = await localStorage.getItem('plbor_expiration_time');

    // If expiration_time does not exist, token is not valid
    if (!expirationTime) {
      return false;
    }

    // get the current timestamp in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // check if the current time is past the token's expiration time
    return currentTime < parseInt(expirationTime);
  }

  hideShowPassword(type) {
    if (type == "password") {
      this.showPasswordText = !this.showPasswordText;
    } else {
      this.showConfirmPasswordText = !this.showConfirmPasswordText;
    }


  }

  selectFile(event) {
    this.errorUploadingLogo = false;
    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return
    }
    let file: File = event.target.files[0];
    // after here 'file' can be accessed and used for further process
    this.previewLogo(file);
    this.uploadLogoToFireBase(file);

  }

  uploadLogoToFireBase(file) {
    this.data.uploadOtherFiles(file)
      .then(uploadTask => {
        uploadTask.ref.getDownloadURL().
          then(
            url => {
              this.model.LogoUrl = url;
            },
            error => {
              this.errorUploadingLogo = true;
            }
          )
      })
      .catch(error => {
        this.errorUploadingLogo = true;
      });
  }

  previewLogo(file) {
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  // Get Trades
  getTrades(data: any[]) {

    this.userTrades$ = data as any[];
    this.userTrades2$ = [];
    this.junkObj = {};

    this.maxColumns = 3;
    this.maxRows = Math.floor(this.userTrades$.length / this.maxColumns);
    this.m = this.userTrades$.length;

    for (let i = 0; i < this.userTrades$.length; i++) {
      this.colNum = Math.floor((((this.m - (i + 1)) / this.maxRows)));
      this.finalColNum = this.colNum >= this.maxColumns ? this.maxColumns - 1 : this.colNum;

      console.log(i);
      this.junkObj = {
        'Id': this.userTrades$[i].Id,
        'Name': this.userTrades$[i].Name,
        'Column': this.finalColNum,
        'IsChecked': false
      };
      this.userTrades2$.push(this.junkObj);
    }

  }

  // Get Divisions
  getDivisions(data: any[]) {

    this.userDivisions$ = data as any[];
    this.userDivisions2$ = [];
    this.junkObj = {};

    this.maxColumns = 3;
    this.maxRows = Math.floor(this.userDivisions$.length / this.maxColumns);
    this.m = this.userDivisions$.length;

    for (let i = 0; i < this.userDivisions$.length; i++) {
      this.colNum = Math.floor((((this.m - (i + 1)) / this.maxRows)));
      this.finalColNum = this.colNum >= this.maxColumns ? this.maxColumns - 1 : this.colNum;

      console.log(i);
      this.junkObj = {
        'Id': this.userDivisions$[i].Id,
        'Name': this.userDivisions$[i].Name,
        'Column': this.finalColNum,
        'IsChecked': false
      };
      this.userDivisions2$.push(this.junkObj);
    }
  }

  // Set the selected User Type
  userTypeSelectedChangeHandler(event: any) {
    this.selectedUserType = event.target.value;
  }

  // User selects Trade checkbox
  tradeLineItemCheckedHandler(event: any) {
    this.id = event.target.id;
    if (event.target.checked) {
      console.log(`checked: ${this.id}`);
      if (!this.isInArray(this.id, this.selectedTrades)) {
        this.selectedTrades.push(this.id);
      }
    } else {
      console.log(`un-checked: ${this.id}`);
      if (this.isInArray(this.id, this.selectedTrades)) {
        this.tempArray = [];
        for (let i = 0; i < this.selectedTrades.length; i++) {
          if (this.selectedTrades[i] !== this.id) {
            this.tempArray.push(this.selectedTrades[i]);
          }
        }
        this.selectedTrades = this.tempArray;
      }
    }
  }

  // User selects Division checkbox
  divisionCheckboxCheckedHandler(event: any) {
    this.id = event.target.id;
    if (event.target.checked) {
      console.log(`checked: ${this.id}`);
      if (!this.isInArray(this.id, this.selectedDivisions)) {
        this.selectedDivisions.push(this.id);
      }
    } else {
      console.log(`un-checked: ${this.id}`);
      if (this.isInArray(this.id, this.selectedDivisions)) {
        this.tempArray = [];
        for (let i = 0; i < this.selectedDivisions.length; i++) {
          if (this.selectedDivisions[i] !== this.id) {
            this.tempArray.push(this.selectedDivisions[i]);
          }
        }
        this.selectedDivisions = this.tempArray;
      }
    }
  }

  isInArray(val: any, array: any[]) {
    return array.some(x => x === val);
  }

  // go forward to Vendor screen 2
  getNext() {
    this.userSelectedDivisions = [];
    console.log(this.userDivisions$);

    for (let i = 0; i < this.userDivisions$.length; i++) {
      for (let j = 0; j < this.selectedDivisions.length; j++) {
        if (this.userDivisions$[i].Id === (this.selectedDivisions[j] - 0)) {
          console.log('this.userDivisions$[i]', this.userDivisions$[i])
          this.userSelectedDivisions.push(this.userDivisions$[i]);
        }
      }
    }
    this.showView1 = false;
    console.log('00', this.userSelectedDivisions);
  }

  // go back to Vendor screen 1
  goBack() {
    this.selectedDivisions = [];
    this.showView1 = true;
  }

  // User selects Division Line Item checkbox
  divisionLineItemCheckedHandler(event: any) {

    this.id = event.target.id;

    if (event.target.checked) {
      if (!this.isInArray(this.id, this.selectedDivisionLineItems)) {
        this.selectedDivisionLineItems.push(this.id);
      }
    } else {
      console.log(`unchecked: ${this.id}`);
      if (this.isInArray(this.id, this.selectedDivisionLineItems)) {
        this.tempArray = [];
        for (let i = 0; i < this.selectedDivisionLineItems.length; i++) {
          if (this.selectedDivisionLineItems[i] !== this.id) {
            this.tempArray.push(this.selectedDivisionLineItems[i]);
          }
        }
        this.selectedDivisionLineItems = this.tempArray;
      }
    }
  }

  // submit the form
  onSubmit() {
    console.log('submit', this.selectedTrades);
    // if Vendor, get selected Division Line Items
    if (this.selectedUserType === this.vendorId) {
      if (this.selectedDivisionLineItems.length === 0) {
        alert('You must select at least one division!');
        return;
      }

      const sortedSelectedDivisionLineItems = this.selectedDivisionLineItems.sort((a, b) => a > b ? 1 : 0);
      const divisions = [];
      let divisionLineItemObj = { Id: 0, LineItems: [] };
      let ctr = 0;
      let currentDivisionId;

      sortedSelectedDivisionLineItems.map((x) => {
        const divisionId = x.split('_')[0];
        const lineItemId = x.split('_')[1];

        if (ctr === 0) {
          divisionLineItemObj.Id = divisionId;
          divisionLineItemObj.LineItems = [];
          currentDivisionId = divisionId;
        } else {
          if (divisionId !== currentDivisionId) {
            divisions.push(divisionLineItemObj);
            divisionLineItemObj = { Id: divisionId, LineItems: [] };
            currentDivisionId = divisionId;
          }
        }
        divisionLineItemObj.LineItems.push({ Id: lineItemId });
        if ((ctr + 1) === sortedSelectedDivisionLineItems.length) {
          divisions.push(divisionLineItemObj);
        }
        ctr++;
      });

      this.model.Divisions = divisions;
    }

    // if Tradesman, get selected Trades
    if (this.selectedUserType === this.tradesmanId) {
      if (this.selectedTrades.length === 0) {
        alert('You must select at least one trade!');
        return;
      }

      const trades = [];

      this.selectedTrades.map((x) => {
        trades.push({ Id: x });
      });

      this.model.Trades = trades;
    }

    // do email match
    if (this.model.User.Email !== this.dummyModel.emailConfirm) {
      alert('Email do not match!');
      return;
    }

    // do passwords match
    if (this.model.User.Password !== this.dummyModel.passwordConfirm) {
      alert('Passwords do not match!');
      return;
    }
    if (this.model.LogoUrl == '') {
      alert('Please select your compnay Logo to continue!');
      this.errorUploadingLogo = true;
      return;
    }

    this.showLoder = true;
    this.model.User.Name = `${this.dummyModel.firstName} ${this.dummyModel.lastName}`;
    this.model.UserTypeId = this.selectedUserType;

    // POST to Api
    this.data.addPrivateLabeler(this.model).subscribe(
      (data: any) => {
        console.log(data);
        this.showLoder = false;
        alert('Please verify your email.  We just sent you a message to do such.  Once you confirm  your email you will receive a (1) link to download the application and (2) a link to put on your website to allow your users to register and download the app!');
        window.location.href = `${this.environment.PrivateLabelBackofficeHttpBaseUrl}/home`;
      },
      (error: any) => {
        this.showLoder = false;
        console.log(error);
      }
    );
    console.log();
  }

  tagItems = [
    { id: 1, title: "Number one" },
    { id: 2, title: "Number two" },
    { id: 3, title: "Number three" }
  ];

  selectedTagItems = [];
  isDisabled = false;

  async onTagItemAdded(data) {
    console.log("onTagItemAdded");
    console.log("item = ", data);
  }

  async onTagItemRemoved(data) {
    console.log("onTagItemRemoved");
    console.log("item = ", data);
  }
}
