import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { AngularFireStorage } from 'angularfire2/storage';
import { Environment } from '../models/Environment';
import { storage } from 'firebase';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private environment: Environment;
	private apiEndpoint: string;

	constructor(private http: HttpClient, 
		) {
		this.environment = new Environment();
		this.apiEndpoint = this.environment.HttpBaseUrl;
	}

	getUserTypes() {
		return this.http.get(`${this.apiEndpoint}/api/userTypes`);
	}

	getTrades() {
		return this.http.get(`${this.apiEndpoint}/api/trades`);
	}

	getDivisions() {
		return this.http.get(`${this.apiEndpoint}/api/division/getDivisions`);
	}

	addPrivateLabeler(payload: any): Observable<any> {
		return this.http.post<any>(`${this.apiEndpoint}/api/privateLabeler`, payload, {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		});
	}

	// @Todo
	uploadOtherFiles(file) {
		let firebaseFileUrl = `logos/${file.fieName}`;
		const pictures = storage().ref(firebaseFileUrl);

		return pictures.put(file, { contentType: file.type });
	}
}
