import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { RegisterComponent } from './pages/register/register.component';

import { MyFilterPipe } from './shared/my-filter.pipe';
import { MyFilterPipe2 } from './shared/my-filter2.pipe';

import { NgxLoadingModule } from 'ngx-loading';
// import { environment } from 'src/environments/environment';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AuthInterceptor } from './core/AuthInterceptor';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    MyFilterPipe,
    MyFilterPipe2
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
