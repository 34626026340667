import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;

    let id = localStorage.getItem("plbor_AuthToken") ?? '';

    if (id === undefined
      || id === null
      || id === '') {
      // alert('missing token');
      // throw new Error('missing token');
    }

    if (!authReq.url.includes('userTypes')) {
      if (authReq.url.includes('Document')) {
        authReq = req.clone({ headers: new HttpHeaders({ 'Authorization': `Bearer ${id}` }) });
      } else {
        authReq = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${id}`
          })
        });
      }
    }

    return next.handle(authReq);
  }
}

