import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myFilter',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], filter: any[]): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.Name.indexOf(filter.filter(x => x.Name === item.Name)) !== -1);
    }
}
