import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myFilter2',
    pure: false
})
export class MyFilterPipe2 implements PipeTransform {
    tempArray: any[];
    transform(items: any[], filter: any[]): any {
        if (!items || !filter) {
            return items;
        }

        this.tempArray = [];
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < filter.length; j++) {
                if (items[i].Column === filter[j].Column) {
                    this.tempArray.push(items[i]);
                }
            }
        }
        return this.tempArray;
        // return items.filter(item => item.Column.indexOf(filter.filter(x => x.Column === item.Column)) !== -1);
        // console.log(`filter`);
        // console.log(filter);
        // console.log(filter.filter(x => x.Column));
        // console.log(filter.map(x => x.Column));
        // return items.filter(x => x.Column === filter.map(y => y.Column));
        // return items.filter(x => x.Column === 1);
        // return items.filter(item => item.Column === (filter.filter(x => x.Column === item.Column)));
    }
}
