// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
// 	production: false,
// 	firebaseConfig: {
// 		apiKey: "AIzaSyDQFqe7KxNrObCHxZi61tmBko9eNW5vnH0",
// 		authDomain: "itt-content.firebaseapp.com",
// 		databaseURL: "https://itt-content.firebaseio.com",
// 		projectId: "itt-content",
// 		storageBucket: "itt-content.appspot.com",
// 		messagingSenderId: "71576110052"
// 	}
// };
export const environment = {
	production: false,
	baseUrl: "https://homeazzon-webapi-dev-eastus2-001.azurewebsites.net/api",
	httpBase: "https://homeazzon-webapi-dev-eastus2-001.azurewebsites.net/",
    //azureB2CUrl: 'https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fstapp-backoffice-dev-eastus2-001.azurewebsites.net%2Fauth&scope=openid&response_type=id_token&prompt=login',
	//azureB2CUrl: 'https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fhomeazzon-backoffice.cognitivegenerationenterprises.com&scope=openid&response_type=id_token&prompt=login',
	azureB2CUrl: 'https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login',
	//baseUrl: "https://localhost:5001/api",
	//httpBase: "https://localhost:5001",
	firebaseConfig: {
		apiKey: "AIzaSyCdVct1XEXNX3j1Kuc4h3i2javjFrKWnaY",
		authDomain: "homeazzon.firebaseapp.com",
		databaseURL: "https://homeazzon.firebaseio.com",
		projectId: "homeazzon",
		storageBucket: "homeazzon.appspot.com",
		messagingSenderId: "885892578415"
	}
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
