<div class="main-wrapper">
  <!-- left side  -->
  <div class="left-side-main">
    <div class="leftHeader">
      <h1>
        Welcome to HomeaZZon - <span>Your Property Management Hub</span>
      </h1>
      <p>
        Access the tools and resources to seamlessly manage your
        properties and enhance your real estate experience.
      </p>
    </div>
    <div class="left-Img">
      <img src="assets/imgs/group-img.png" alt="" />
    </div>
  </div>

  <!-- right side -->
  <div class="right-side-main">
    <div class="right-header-img flex-center">
      <img src="assets/imgs/home-logo.png" alt="">
    </div>
    <div class="right-header flex-center">
      <span>Private Label Registration</span>
    </div>
    <!--<div class="sign-in-heading flex-center" *ngIf="!isUserLoggedIn">
      Or <span> Sign in</span>
    </div>-->

    <div class="login-btn" *ngIf="!isUserLoggedIn">
      <button type="submit" class="submit-btn" style="float:right;" (click)="redirectToAzure()">Register Now!</button>
    </div>

    <ng-template #customLoadingTemplate>
      <div class="custom-class" *ngIf="isUserLoggedIn">
        <h3>
          Saving...
        </h3>
      </div>
    </ng-template>
    <form *ngIf="isUserLoggedIn" name="form" class="w-100" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-heading">
        <span>Company Information</span>
      </div>
      <div class="main-fields-wrapper" *ngIf="!showTrades">
        <div class="row mb-17">
          <div class="form-group col-6 pl-0">
            <label for="Name">Company Name</label>
            <input type="text" class="form-control" name="Name" [(ngModel)]="model.Name" #Name="ngModel"
                   [ngClass]="{ 'is-invalid': f.submitted && Name.invalid }" placeholder="Company Name" required>
            <div *ngIf="f.submitted && Name.invalid" class="invalid-feedback">
              <div *ngIf="Name.errors?.['required']">Company Name is required</div>
            </div>
          </div>
          <div class="form-group col-6 pr-0">
            <label for="Name">Company Logo</label>
            <div class="row upload-main ml-0">
              <span class="preview-img col-5 p-0" *ngIf="!errorUploadingLogo">
                <!-- <div class="w-100 h-100 flex-center" >  -->
                <img src="assets/icon/Image 2.svg" class="img-fluid" *ngIf="!previewUrl">
                <img [src]="previewUrl" class="img-shown" *ngIf="previewUrl">
                <!-- </div> -->
              </span>
              <div class="image-upload col-6">
                <label for="img" class="upload-img">
                  Upload Logo
                  <input id="img" type="file" class="form-control" name="file" required (change)="selectFile($event)"
                         accept="image/*">
                </label>
                <div class="alert-danger" *ngIf="errorUploadingLogo">
                  Please select your company logo.
                </div>
                <span *ngIf="!errorUploadingLogo">pick an image up to 4mb</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-17">
          <div class="form-group col-6 pl-0">
            <label for="WebsiteUrl">Website URL</label>
            <input type="url" class="form-control" name="WebsiteUrl" [(ngModel)]="model.WebsiteUrl"
                   #WebsiteUrl="ngModel" [ngClass]="{ 'is-invalid': f.submitted && WebsiteUrl.invalid }"
                   placeholder="Website Url" required>
            <div *ngIf="f.submitted && WebsiteUrl.invalid" class="invalid-feedback">
              <div *ngIf="WebsiteUrl.errors?.['required']">Website Url is required</div>
            </div>
          </div>
          <div class="form-group col-6 pr-0">
            <label for="exampleInputFile">User Type</label>
            <select class="form-control" name="user type" [(ngModel)]="selectedUserType" (change)="userTypeSelectedChangeHandler($event)">
              <option disabled [value]="null"> Select User Type </option>
              <option [value]="userType.Id" *ngFor="let userType of userTypes$ | myFilter:filterargs">
                {{userType.Name}}
              </option>
            </select>
          </div>
        </div>

        <div class="flex-center gap-20 mt-5">
          <button class="submit-btn" (click)="showTrades = !showTrades">Continue</button>
        </div>

        <!-- <div *ngFor="let trade of userTrades2$ | myFilter2:col1">
            <label>
              <input type="checkbox" id="{{trade.Id}}" ng-model="trade.IsChecked"
                     (change)="tradeLineItemCheckedHandler($event)" /> &nbsp;<span>{{trade.Name}}</span>
            </label>
          </div> -->

      </div>
      <div class="main-fields-wrapper" *ngIf="showTrades">
        <div class="form-group">
          <div class="row min-height" *ngIf="selectedUserType == tradesmanId || selectedUserType == vendorId">
            <div class="form-group col-12 pl-0 pr-0" *ngIf="selectedUserType == tradesmanId">
              <label>Select Trades</label>

              <tag-input [(ngModel)]="selectedTrades" [identifyBy]="'Id'" [disable]="isDisabled" [displayBy]="'Name'"
                         name="trade">
                <tag-input-dropdown [appendToBody]="true" [showDropdownIfEmpty]="true"
                                    [autocompleteItems]="userTrades2$" [identifyBy]="'Id'" [displayBy]="'Name'">
                </tag-input-dropdown>
              </tag-input>

            </div>

            <div class="form-group col-12 pl-0 pr-0" *ngIf="selectedUserType == vendorId && showView1">
              <label>Select Divisions</label>
              <div class="row ml-0">
                <div class="col-10 pl-0">
                  <tag-input [(ngModel)]="selectedTrades" [identifyBy]="'Id'" [disable]="isDisabled"
                             [displayBy]="'Name'" name="trade">
                    <tag-input-dropdown [appendToBody]="true" [showDropdownIfEmpty]="true"
                                        [autocompleteItems]="userDivisions2$" [identifyBy]="'Id'" [displayBy]="'Name'">
                    </tag-input-dropdown>
                  </tag-input>
                </div>


                <div class="col-2 p-0">
                  <button (click)="getNext()" class="next-btn">Next</button>
                </div>
              </div>

            </div>
            <div class="form-group col-12 pl-0 pr-0" *ngIf="!showView1">
              <label>Select Division Line Items</label>
              <div class="row ml-0">
                <div class="col-10 pl-0">
                  <tag-input [(ngModel)]="selectedTrades" [identifyBy]="'Id'" [displayBy]="'Name'" name="trade">
                    <tag-input-dropdown [appendToBody]="true" [showDropdownIfEmpty]="true"
                                        [autocompleteItems]="userSelectedDivisions" [identifyBy]="'Id'" [displayBy]="'Name'">
                    </tag-input-dropdown>
                  </tag-input>
                </div>
                <div class="col-2 p-0">
                  <button (click)="goBack()" class="btn btn-danger back-btn">Back</button>

                </div>
              </div>
            </div>
          </div>
          <ngx-loading [show]="showLoder" [config]="{ backdropBorderRadius: '3px' }"
                       [template]="customLoadingTemplate"></ngx-loading>
          <div class="flex-center gap-20 mt-5">
            <button class="go-back-btn pointer" (click)="showTrades = !showTrades">
              <img src="assets/icon/Arrow - Left 2.svg">Go Back
            </button>
            <button type="submit" class="submit-btn" style="float:right;">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
