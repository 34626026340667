import { Component } from '@angular/core';
import { initializeApp } from 'firebase';
import {environment} from './environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'private-label';

  constructor(){
    initializeApp(environment.firebaseConfig);
  }
}
