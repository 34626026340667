import { environment } from "../../environments/environment";

export class Environment {

  HttpBaseUrl: string = this.getBaseUri();
  PrivateLabelBackofficeHttpBaseUrl: string = this.getPrivateLabelBackofficeHttpBaseUrl();

  getBaseUri(): string {
    let uri: string = '-001.azurewebsites.net';
    let env: string = this.determineEnvironment();
    let protocol: string = 'https';

    if (env === 'localhost') {
      uri = `${env}:5001`;
      protocol = 'https';
    } else {
      uri = `${env}${uri}`;
    }

    let baseUri: string = `${protocol}://${uri}`;
    //let baseUri: string = `https://homeazzon-webapi-dev-eastus2-001.azurewebsites.net`;
    return baseUri;
  }

  // Obselete
  getPrivateLabelBackofficeHttpBaseUrl(): string {
    let uri: string = '-001.azurewebsites.net';
    let env: string = this.determineEnvironment();
    let protocol: string = 'https';

    if (env === 'localhost') {
      uri = ':8100';
      protocol = 'http';
    }

    let baseUri: string = `${protocol}://uat${uri}`;
    return baseUri;
  }

  determineEnvironment(): string {
    let host: string = window.location.host.toString();

    if (host.indexOf('localhost') !== -1) {
      return 'localhost';
    }

    if ((host.indexOf('dev') !== -1
      && (host.indexOf('homeazzon') !== -1
        || host.indexOf('sthzz') !== -1
        || host.indexOf('dev.pl.r.001') !== -1
        || host.indexOf('dev.privatelabel') !== -1
        || host.indexOf('app-hzz-pl-register') !== -1)) || (host.indexOf('xyz.sqlsafekeep') !== -1 || host.indexOf('fde-homeazzon-pl-registration') !== -1)) {
      //homeazzon-webapi-dev-eastus2 // dev
      return 'homeazzon-webapi-dev-eastus2';
    }
    if (host.indexOf('qa') !== -1 && host.indexOf('homeazzon') !== -1) {
      //app-homeazzon-api-qa-easus2 // qa
      return 'app-homeazzon-api-qa-easus2';
    }
    if (host.indexOf('uat') !== -1 && host.indexOf('homeazzon') !== -1) {
      return 'uat';
    }
    if (host.indexOf('prod') !== -1 && host.indexOf('homeazzon') !== -1) {
      //app-homeazzon-api-qa-easus2
      return 'app-homeazzon-api-prod-easus2';
    }
    throw new Error('invalid location');
  }

  determineAzureB2CUrl(): string {
    let host: string = window.location.host.toString();

    if (host.indexOf('localhost') !== -1) {
      return environment.azureB2CUrl.privateLabelRegistration_Local;
    }
    if (host.indexOf('sthzzplbordeveastus2002') !== -1) {
      return environment.azureB2CUrl.storagePrivateLabelRegistration_Dev;
    }
    if (host.indexOf('fde-homeazzon-pl-registration-dev-002') !== -1) {
      return environment.azureB2CUrl.privateLabelRegistration_Dev_AFD_Test;
    }
    if (host.indexOf('dev.pl.r.001.homeazzon.com') !== -1) {
      return environment.azureB2CUrl.privateLabelRegistration_Dev_Test;
    }
    if (host.indexOf('dev.privatelabel-registration') !== -1) {
      return environment.azureB2CUrl.privateLabelRegistration_Dev;
    }
    if (host.indexOf('xyz.sqlsafekeep.com') !== -1) {
      return environment.azureB2CUrl.privateLabelRegistration_Dev_SSK;
    }

    throw new Error('invalid request');
  }
}
