// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDQFqe7KxNrObCHxZi61tmBko9eNW5vnH0",
    authDomain: "itt-content.firebaseapp.com",
    databaseURL: "https://itt-content.firebaseio.com",
    projectId: "itt-content",
    storageBucket: "itt-content.appspot.com",
    messagingSenderId: "71576110052"
  },
  azureB2CUrl: {
    privateLabelRegistration_Local: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    storagePrivateLabelRegistration_Dev: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fsthzzplbordeveastus2002.z20.web.core.windows.net%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    storagePrivateLabelRegistration_QA: "",
    storagePrivateLabelRegistration_UAT: "",
    storagePrivateLabelRegistration_Prod: "",
    privateLabelRegistration_Dev: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdev.privatelabel-registration.homeazzon.com%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    privateLabelRegistration_QA: "",
    privateLabelRegistration_UAT: "",
    privateLabelRegistration_Prod: "",
    privateLabelRegistration_Dev_Test: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdev.pl.r.001.homeazzon.com%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    privateLabelRegistration_Dev_AFD_Test: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Ffde-homeazzon-pl-registration-dev-002-hdh5ddabh8djg4cw.z01.azurefd.net%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    privateLabelRegistration_Dev_AppSvc: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp-hzz-pl-register-dev-eastus2-001.azurewebsites.net%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login",
    privateLabelRegistration_Dev_SSK: "https://cognitivegenerationenterpr.b2clogin.com/cognitivegenerationenterpr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn_Public_HomeaZZon&client_id=67b22975-4c48-4fe3-9b03-913511cfcae5&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fxyz.sqlsafekeep.com%2Fauth&scope=https%3A%2F%2Fcognitivegenerationenterpr.onmicrosoft.com%2Fhomeazzon-api%2Fpl&response_type=token&prompt=login"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
